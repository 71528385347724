.tabs {
	@apply border-b border-borderGray min-h-[0px];
}

.tabsVertical {
	@apply border-r border-borderGray;
}

.tabsIndicator {
    @apply bg-primary
}

.tab {
	@apply text-sm normal-case min-h-[0px] py-2 px-4;
}

.tabVertical {
	@apply text-sm items-end normal-case min-h-[0px] p-3 m-0 pr-4;
}

.tabSelected {
	@apply !text-primaryDark;
}
