.paper {
	@apply bg-white rounded border border-gray-100 shadow-md;
}

.paperPlain {
	@apply bg-white rounded border border-gray-100;
}

.breadcrump {
	@apply bg-bgGray text-xs;
}

.breadcrumpClickable {
	@apply hover:bg-borderGray cursor-pointer;
}

.breadcrumpIcon {
	@apply w-[12px] h-[12px] text-borderGray;
}

.googleIcon {
	background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
	-webkit-text-fill-color: transparent;
}

.contextMenuParent:hover > .contextMenuChild {
	@apply block;
}

.contextMenuChild {
	@apply hidden;
}
