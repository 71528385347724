.buttonGradient {
	color: #ffffff !important;
	background: linear-gradient(90deg, rgba(155, 41, 255, 1), rgba(3, 175, 255, 1)) !important;
	box-shadow: none;
}
.buttonGradient:hover {
	background: linear-gradient(90deg, rgba(116, 30, 192, 1), rgba(3, 125, 182, 1)) !important;
	color: #ffffff !important;
	box-shadow: none;
}
.buttonGradient:visited {
	color: #ffffff !important;
}
.buttonGradient:disabled {
	background: linear-gradient(90deg, rgba(90, 20, 170, 1), rgba(3, 90, 152, 1)) !important;
	color: rgba(100, 100, 100, 1) !important;
}
.buttonOutlinedGray {
	@apply border-borderForm text-textGray;
}
.buttonOutlinedGray:hover {
	@apply border-black bg-bgGray;
}
.buttonOutlinedPrimary {
	@apply border-primary text-primary;
}
.buttonOutlinedPrimary:hover {
	@apply border-primaryDark bg-primaryLight;
}
.buttonContainedPrimary {
	@apply !bg-primary hover:!bg-primaryDark text-white shadow-none hover:shadow-none;
}
.buttonContainedContrast {
	@apply !bg-barActive hover:!bg-barActiveHover shadow-none hover:shadow-none text-barTextStrong;
}
.buttonContainedGray {
	@apply !bg-bgGray hover:!bg-borderGray text-textGray shadow-none hover:shadow-none;
}
.buttonText {
	@apply text-textGray hover:bg-bgGray;
}
.buttonTextPrimary {
	@apply text-primary hover:bg-primaryLight;
}
