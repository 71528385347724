@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'common/styles/index.scss';

/* General classes */
body {
	height: 100%;
	margin: 0;
	font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html,
#root {
	height: 100%;
}

/* Themes */
@layer base {
	:root {
		/* Default color definition */
		--stheme-blue1: #4299e1;
		--stheme-red1: #f56565;
		--stheme-green1: #48bb78;
		--stheme-yellow1: #ecc94b;
		--stheme-purple1: #9f7aea;
		--stheme-pink1: #ed64a6;
		--stheme-orange1: #ed8936;
		--stheme-gray1: #a0aec0;
		--stheme-teal1: #38b2ac;
		--stheme-cyan1: #0bc5ea;
		--stheme-blue2: #2b6cb0;
		--stheme-red2: #c53030;
		--stheme-green2: #2f855a;
		--stheme-yellow2: #b7791f;
		--stheme-purple2: #6b46c1;
		--stheme-pink2: #b83280;
		--stheme-orange2: #c05621;
		--stheme-teal2: #2c7a7b;
		--stheme-cyan2: #00a3c4;
		--stheme-gray2: #1d1d1d;

		/* Root == stheme-gray2 */
		/* Default: used in color picker, charts, etc */
		--default: #1d1d1d;
		/* General UI colors */
		--primary: #4299e1;
		--primary-dark: #2b6cb0;
		--primary-light: #ebf8ff; /*color50*/
		/* Top bar colors */
		--bar-bg: #1d1d1d;
		--bar-active: #4299e1; /*gray600*/
		--bar-active-hover: #3182ce; /*gray900*/
		--bar-text-strong: #ffffff;
		--bar-text-light: #d4d2d2;
		--bar-border-strong: #868686; /*color600*/
		--bar-border-light: #474747; /*color500*/
	}
	.stheme-blue1 {
		/* Default: used in color picker, charts, etc */
		--default: var(--stheme-blue1);
		/* General UI colors */
		--primary: #4299e1; /*color400*/
		--primary-dark: #2b6cb0;
		--primary-light: #ebf8ff; /*color50*/
		/* Top bar colors */
		--bar-bg: #4299e1;
		--bar-active: #4a5568; /*gray600*/
		--bar-active-hover: #171923; /*gray900*/
		--bar-text-strong: #ffffff;
		--bar-text-light: #f0f0f0;
		--bar-border-strong: #2b6cb0; /*color600*/
		--bar-border-light: #3182ce; /*color500*/
	}
	.stheme-red1 {
		/* Default: used in color picker, charts, etc */
		--default: var(--stheme-red1);
		/* General UI colors */
		--primary: #f56565; /*color400*/
		--primary-dark: #c53030;
		--primary-light: #fff5f5; /*color50*/
		/* Top bar colors */
		--bar-bg: #f56565;
		--bar-active: #4a5568; /*gray600*/
		--bar-active-hover: #171923; /*gray900*/
		--bar-text-strong: #ffffff;
		--bar-text-light: #f0f0f0;
		--bar-border-strong: #c53030; /*color600*/
		--bar-border-light: #e53e3e; /*color500*/
	}
	.stheme-green1 {
		/* Default: used in color picker, charts, etc */
		--default: var(--stheme-green1);
		/* General UI colors */
		--primary: #48bb78; /*color400*/
		--primary-dark: #2f855a;
		--primary-light: #f0fff4; /*color50*/
		/* Top bar colors */
		--bar-bg: #48bb78;
		--bar-active: #4a5568; /*gray600*/
		--bar-active-hover: #171923; /*gray900*/
		--bar-text-strong: #ffffff;
		--bar-text-light: #f0f0f0;
		--bar-border-strong: #2f855a; /*color600*/
		--bar-border-light: #38a169; /*color500*/
	}
	.stheme-yellow1 {
		/* Default: used in color picker, charts, etc */
		--default: var(--stheme-yellow1);
		/* General UI colors */
		--primary: #ecc94b; /*color400*/
		--primary-dark: #b7791f; /*color600*/
		--primary-light: #fffff0; /*color50*/
		/* Top bar colors */
		--bar-bg: #ecc94b;
		--bar-active: #4a5568; /*gray600*/
		--bar-active-hover: #171923; /*gray900*/
		--bar-text-strong: #ffffff;
		--bar-text-light: #f0f0f0;
		--bar-border-strong: #b7791f; /*color600*/
		--bar-border-light: #d69e2e; /*color500*/
	}
	.stheme-purple1 {
		/* Default: used in color picker, charts, etc */
		--default: var(--stheme-purple1);
		/* General UI colors */
		--primary: #9f7aea; /*color400*/
		--primary-dark: #6b46c1; /*color600*/
		--primary-light: #faf5ff; /*color50*/
		/* Top bar colors */
		--bar-bg: #9f7aea;
		--bar-active: #4a5568; /*gray600*/
		--bar-active-hover: #171923; /*gray900*/
		--bar-text-strong: #ffffff;
		--bar-text-light: #f0f0f0;
		--bar-border-strong: #6b46c1; /*color600*/
		--bar-border-light: #805ad5; /*color500*/
	}
	.stheme-pink1 {
		/* Default: used in color picker, charts, etc */
		--default: var(--stheme-pink1);
		/* General UI colors */
		--primary: #ed64a6; /*color400*/
		--primary-dark: #b83280; /*color600*/
		--primary-light: #fff5f7; /*color50*/
		/* Top bar colors */
		--bar-bg: #ed64a6;
		--bar-active: #4a5568; /*gray600*/
		--bar-active-hover: #171923; /*gray900*/
		--bar-text-strong: #ffffff;
		--bar-text-light: #f0f0f0;
		--bar-border-strong: #b83280; /*color600*/
		--bar-border-light: #d53f8c; /*color500*/
	}
	.stheme-orange1 {
		/* Default: used in color picker, charts, etc */
		--default: var(--stheme-orange1);
		/* General UI colors */
		--primary: #ed8936; /*color400*/
		--primary-dark: #c05621; /*color600*/
		--primary-light: #fffaf0; /*color50*/
		/* Top bar colors */
		--bar-bg: #ed8936;
		--bar-active: #4a5568; /*gray600*/
		--bar-active-hover: #171923; /*gray900*/
		--bar-text-strong: #ffffff;
		--bar-text-light: #f0f0f0;
		--bar-border-strong: #c05621; /*color600*/
		--bar-border-light: #dd6b20; /*color500*/
	}
	.stheme-gray1 {
		/* Default: used in color picker, charts, etc */
		--default: var(--stheme-gray1);
		/* General UI colors */
		--primary: #a0aec0; /*color400*/
		--primary-dark: #4a5568; /*color600*/
		--primary-light: #f7fafc; /*color50*/
		/* Top bar colors */
		--bar-bg: #a0aec0;
		--bar-active: #4a5568; /*gray600*/
		--bar-active-hover: #171923; /*gray900*/
		--bar-text-strong: #ffffff;
		--bar-text-light: #f0f0f0;
		--bar-border-strong: #4a5568; /*color600*/
		--bar-border-light: #718096; /*color500*/
	}
	.stheme-teal1 {
		/* Default: used in color picker, charts, etc */
		--default: var(--stheme-teal1);
		/* General UI colors */
		--primary: #38b2ac; /*color400*/
		--primary-dark: #2c7a7b; /*color600*/
		--primary-light: #e6fffa; /*color50*/
		/* Top bar colors */
		--bar-bg: #38b2ac;
		--bar-active: #4a5568; /*gray600*/
		--bar-active-hover: #171923; /*gray900*/
		--bar-text-strong: #ffffff;
		--bar-text-light: #f0f0f0;
		--bar-border-strong: #2c7a7b; /*color600*/
		--bar-border-light: #319795; /*color500*/
	}
	.stheme-cyan1 {
		/* Default: used in color picker, charts, etc */
		--default: var(--stheme-cyan1);
		/* General UI colors */
		--primary: #0bc5ea; /*color400*/
		--primary-dark: #00a3c4; /*color600*/
		--primary-light: #edfdfd; /*color50*/
		/* Top bar colors */
		--bar-bg: #0bc5ea;
		--bar-active: #4a5568; /*gray600*/
		--bar-active-hover: #171923; /*gray900*/
		--bar-text-strong: #ffffff;
		--bar-text-light: #f0f0f0;
		--bar-border-strong: #00a3c4; /*color600*/
		--bar-border-light: #00b5d8; /*color500*/
	}

	.stheme-blue2 {
		/* Default: used in color picker, charts, etc */
		--default: var(--stheme-blue2);
		/* General UI colors */
		--primary: #2b6cb0; /*color600*/
		--primary-dark: #63b3ed; /*color300*/
		--primary-light: #ebf8ff; /*color50*/
		/* Top bar colors */
		--bar-bg: #2b6cb0;
		--bar-active: #a0aec0; /*gray400*/
		--bar-active-hover: #718096; /*gray500*/
		--bar-text-strong: #ffffff;
		--bar-text-light: #f0f0f0;
		--bar-border-strong: #63b3ed; /*color300*/
		--bar-border-light: #3182ce; /*color500*/
	}
	.stheme-red2 {
		/* Default: used in color picker, charts, etc */
		--default: var(--stheme-red2);
		/* General UI colors */
		--primary: #c53030; /*color600*/
		--primary-dark: #fc8181; /*color300*/
		--primary-light: #fff5f5; /*color50*/
		/* Top bar colors */
		--bar-bg: #c53030;
		--bar-active: #a0aec0; /*gray400*/
		--bar-active-hover: #718096; /*gray500*/
		--bar-text-strong: #ffffff;
		--bar-text-light: #f0f0f0;
		--bar-border-strong: #fc8181; /*color300*/
		--bar-border-light: #e53e3e; /*color500*/
	}
	.stheme-green2 {
		/* Default: used in color picker, charts, etc */
		--default: var(--stheme-green2);
		/* General UI colors */
		--primary: #2f855a; /*color600*/
		--primary-dark: #68d391; /*color300*/
		--primary-light: #f0fff4; /*color50*/
		/* Top bar colors */
		--bar-bg: #2f855a;
		--bar-active: #a0aec0; /*gray400*/
		--bar-active-hover: #718096; /*gray500*/
		--bar-text-strong: #ffffff;
		--bar-text-light: #f0f0f0;
		--bar-border-strong: #68d391; /*color300*/
		--bar-border-light: #38a169; /*color500*/
	}
	.stheme-yellow2 {
		/* Default: used in color picker, charts, etc */
		--default: var(--stheme-yellow2);
		/* General UI colors */
		--primary: #b7791f; /*color600*/
		--primary-dark: #f6e05e; /*color300*/
		--primary-light: #fffff0; /*color50*/
		/* Top bar colors */
		--bar-bg: #b7791f;
		--bar-active: #a0aec0; /*gray400*/
		--bar-active-hover: #718096; /*gray500*/
		--bar-text-strong: #ffffff;
		--bar-text-light: #f0f0f0;
		--bar-border-strong: #f6e05e; /*color300*/
		--bar-border-light: #d69e2e; /*color500*/
	}
	.stheme-purple2 {
		/* Default: used in color picker, charts, etc */
		--default: var(--stheme-purple2);
		/* General UI colors */
		--primary: #6b46c1; /*color600*/
		--primary-dark: #b794f4; /*color300*/
		--primary-light: #faf5ff; /*color50*/
		/* Top bar colors */
		--bar-bg: #6b46c1;
		--bar-active: #a0aec0; /*gray400*/
		--bar-active-hover: #718096; /*gray500*/
		--bar-text-strong: #ffffff;
		--bar-text-light: #f0f0f0;
		--bar-border-strong: #b794f4; /*color300*/
		--bar-border-light: #805ad5; /*color500*/
	}
	.stheme-pink2 {
		/* Default: used in color picker, charts, etc */
		--default: var(--stheme-pink2);
		/* General UI colors */
		--primary: #b83280; /*color600*/
		--primary-dark: #f687b3; /*color300*/
		--primary-light: #fff5f7; /*color50*/
		/* Top bar colors */
		--bar-bg: #b83280;
		--bar-active: #a0aec0; /*gray400*/
		--bar-active-hover: #718096; /*gray500*/
		--bar-text-strong: #ffffff;
		--bar-text-light: #f0f0f0;
		--bar-border-strong: #f687b3; /*color300*/
		--bar-border-light: #d53f8c; /*color500*/
	}
	.stheme-orange2 {
		/* Default: used in color picker, charts, etc */
		--default: var(--stheme-orange2);
		/* General UI colors */
		--primary: #c05621; /*color600*/
		--primary-dark: #f6ad55; /*color300*/
		--primary-light: #fffaf0; /*color50*/
		/* Top bar colors */
		--bar-bg: #c05621;
		--bar-active: #a0aec0; /*gray400*/
		--bar-active-hover: #718096; /*gray500*/
		--bar-text-strong: #ffffff;
		--bar-text-light: #f0f0f0;
		--bar-border-strong: #f6ad55; /*color300*/
		--bar-border-light: #dd6b20; /*color500*/
	}
	.stheme-teal2 {
		/* Default: used in color picker, charts, etc */
		--default: var(--stheme-teal2);
		/* General UI colors */
		--primary: #2c7a7b; /*color600*/
		--primary-dark: #4fd1c5; /*color300*/
		--primary-light: #e6fffa; /*color50*/
		/* Top bar colors */
		--bar-bg: #2c7a7b;
		--bar-active: #a0aec0; /*gray400*/
		--bar-active-hover: #718096; /*gray500*/
		--bar-text-strong: #ffffff;
		--bar-text-light: #f0f0f0;
		--bar-border-strong: #4fd1c5; /*color300*/
		--bar-border-light: #319795; /*color500*/
	}
	.stheme-cyan2 {
		/* Default: used in color picker, charts, etc */
		--default: var(--stheme-cyan2);
		/* General UI colors */
		--primary: #00a3c4; /*color600*/
		--primary-dark: #76e4f7; /*color300*/
		--primary-light: #edfdfd; /*color50*/
		/* Top bar colors */
		--bar-bg: #00a3c4;
		--bar-active: #a0aec0; /*gray400*/
		--bar-active-hover: #718096; /*gray500*/
		--bar-text-strong: #ffffff;
		--bar-text-light: #f0f0f0;
		--bar-border-strong: #76e4f7; /*color300*/
		--bar-border-light: #00b5d8; /*color500*/
	}

	.stheme-gray2 {
		/* Default: used in color picker, charts, etc */
		--default: var(--stheme-gray2);
		/* General UI colors */
		--primary: #4299e1;
		--primary-dark: #2b6cb0;
		--primary-light: #ebf8ff; /*color50*/
		/* Top bar colors */
		--bar-bg: #1d1d1d; /*color400*/
		--bar-active: #4299e1; /*gray600*/
		--bar-active-hover: #3182ce; /*gray900*/
		--bar-text-strong: #ffffff;
		--bar-text-light: #d4d2d2;
		--bar-border-strong: #868686; /*color600*/
		--bar-border-light: #474747; /*color500*/
	}
}
