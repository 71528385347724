:root {
	--grid-border-color: #e8ebed;
	--grid-border: 0.5px solid #e8ebed;
	--grid-icon-color: rgba(0, 0, 0, 0.6);
	--grid-icon-error-color: red;
	--grid-icon-warning-color: #ffc107;
	--grid-item-selected-background-color: rgba(240, 240, 240, 1);
	--grid-selection-border-radius: 2px;
	--grid-selection-background-color: rgba(69, 128, 230, 0.04);
	--grid-selection-disabled-border-color: #9da6ab;
	--grid-transition-duration: 0.1s;
	--grid-expand-rows-indicator-width: 10px;
	--grid-scroll-shadow-width: 7px;
	--grid-scroll-shadow-color: rgba(0, 0, 0, 0.2);
}

.grid-hide-scrollbar::-webkit-scrollbar {
	display: none;
}
.grid-hide-scrollbar {
	cursor: cell;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.grid-cell-wrapper {
	background-color: white;
	box-sizing: border-box;
	border: var(--grid-border);
	padding-left: 20px;
	padding-right: 20px;
	display: flex;
	align-items: center;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.grid-cell-formula-wrapper {
	position: absolute;
	display: flex;
	align-items: center;
	z-index: 3;
	background-color: white;
	box-sizing: border-box;
	@apply border-2 border-primary;
}

.grid-cell-formula-wrapper > div {
	flex: 1;
}

/*********************/
/*Grid row header*/
/*********************/
.grid-row-buttons-wrapper {
	position: absolute;
	display: flex;
	right: 0px;
	direction: row;
	justify-content: center;
	align-items: center;
	padding-left: 10px;
	padding-right: 10px;
}
.grid-row-buttons-wrapper-hidden {
	display: none;
	direction: row;
	justify-content: center;
	align-items: center;
}

.grid-cell-wrapper:hover .grid-row-buttons-wrapper-hidden {
	display: flex;
}

.grid-icon-round {
	background: var(--grid-border-color);
	color: rgba(0, 0, 0, 0.6);
	width: 16px;
	height: 16px;
	border-radius: 50%;
	text-align: center;
	line-height: 16px;
	vertical-align: middle;
	padding: 2px;
	margin-right: 10px;
	margin-left: 5px;
	box-shadow: 0.5px 0.5px 1px 1px rgba(60, 64, 67, 0.15);
	cursor: pointer;
}

.grid-icon {
	color: var(--grid-icon-color);
	vertical-align: middle;
	margin-right: 5px;
}

.grid-button {
	cursor: pointer;
	color: var(--grid-icon-color);
}

.grid-button-add,
.grid-button-connect,
.grid-button-settings,
.grid-button-expand {
	cursor: pointer;
	color: var(--grid-icon-color);
	height: 15px;
	width: 15px;
	margin-right: 10px;
	vertical-align: middle;
}

.grid-button:hover,
.grid-button-add:hover,
.grid-button-connect:hover,
.grid-button-settings:hover,
.grid-button-expand:hover {
	color: black;
	background-color: transparent;
}

.grid-icon {
	height: 15px;
	width: 15px;
}

.grid-row-chip {
	height: 16px;
}

/*********************/
/*Grid column resizing*/
/*********************/

.grid-column-resize {
	position: absolute;
	width: 6px;
	top: 0;
	bottom: 0;
	right: -2px;
	cursor: ew-resize;
	z-index: 4;
}

.grid-column-resize:hover {
	background-color: black;
}

/*********************/
/*Grid row dragging*/
/*********************/
.grid-draggable {
}

.grid-row-drag-wrapper {
	position: absolute;
	left: 5px;
	display: flex;
	direction: row;
	justify-content: center;
	align-items: center;
}

.grid-row-drag {
	cursor: move;
	color: rgba(0, 0, 0, 0.6);
	height: 15px;
	width: 15px;
	margin-right: 10px;
}

.grid-row-drag:hover {
	color: black;
}

.grid-row-drop-top {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 50%;
	z-index: 2;
	background-color: transparent;
}

.grid-row-drop-top.dragover {
	border-top: 4px solid black;
}

.grid-row-drop-bottom {
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	bottom: 0;
	z-index: 2;
	background-color: transparent;
}

.grid-row-drop-bottom.dragover {
	border-bottom: 4px solid black;
}

/*********************/
/*Grid col dragging*/
/*********************/

.grid-col-drag-wrapper {
	position: absolute;
	left: 5px;
	display: flex;
	direction: row;
	justify-content: center;
	align-items: center;
}

.grid-col-drag {
	cursor: move;
	color: rgba(0, 0, 0, 0.6);
	height: 15px;
	width: 15px;
	margin-right: 10px;
}

.grid-col-drag:hover {
	color: black;
}

.grid-col-drop-left {
	position: absolute;
	left: 0;
	right: 50%;
	top: 0;
	bottom: 0;
	z-index: 2;
}

.grid-col-drop-left.dragover {
	border-left: 4px solid black;
}

.grid-col-drop-right {
	position: absolute;
	left: 50%;
	right: 0px;
	top: 0;
	bottom: 0;
	z-index: 2;
	background-color: transparent;
}

.grid-col-drop-right.dragover {
	border-right: 4px solid black;
}

/*********************/
/*Grid selection*/
/*********************/
.grid-active-cell,
.grid-selection-rect {
	position: absolute;
	box-sizing: border-box;
	border-radius: var(--grid-selection-border-radius);
	transition: all var(--grid-transition-duration);
	pointer-events: none;
	z-index: 2;
	@apply border-2 border-primary;
}

.grid-selection-rect {
	background: var(--grid-selection-background-color);
}

.grid-active-formulabar {
	display: block;
	flex: 1;
	border: 0.5px solid #e8ebed;
	border-bottom: none;
	align-self: stretch;
	background: none;
	padding-left: 45px;
	padding-right: 15px;
	outline: none;
	margin: 0;
}
.grid-active-formulabar:focus {
	@apply outline-2 outline-primary;
}

.grid-active-cell-focus {
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.grid-active-cell-disabled,
.grid-selection-rect-disabled {
	border-color: var(--grid-selection-disabled-border-color);
}

.grid-scrollable-view {
	position: absolute;
	pointer-events: none;
	box-sizing: border-box;
	border-bottom: none;
	border-right: none;
	transition: box-shadow 0.3s;
}

.grid-scrollable-view-t {
	box-shadow: 0 var(--grid-scroll-shadow-width) var(--grid-scroll-shadow-width) calc(0px - var(--grid-scroll-shadow-width)) var(--grid-scroll-shadow-color) inset,
		0 0 var(--grid-scroll-shadow-width) calc(0px - var(--grid-scroll-shadow-width)) var(--grid-scroll-shadow-color) inset,
		0 0 var(--grid-scroll-shadow-width) calc(0px - var(--grid-scroll-shadow-width)) var(--grid-scroll-shadow-color) inset,
		0 0 var(--grid-scroll-shadow-width) calc(0px - var(--grid-scroll-shadow-width)) var(--grid-scroll-shadow-color) inset;
}

.grid-scrollable-view-r {
	box-shadow: 0 0 var(--grid-scroll-shadow-width) calc(0px - var(--grid-scroll-shadow-width)) var(--grid-scroll-shadow-color) inset,
		calc(0px - var(--grid-scroll-shadow-width)) 0 var(--grid-scroll-shadow-width) calc(0px - var(--grid-scroll-shadow-width)) var(--grid-scroll-shadow-color) inset,
		0 0 var(--grid-scroll-shadow-width) calc(0px - var(--grid-scroll-shadow-width)) var(--grid-scroll-shadow-color) inset,
		0 0 var(--grid-scroll-shadow-width) calc(0px - var(--grid-scroll-shadow-width)) var(--grid-scroll-shadow-color) inset;
}

.grid-scrollable-view-b {
	box-shadow: 0 0 var(--grid-scroll-shadow-width) calc(0px - var(--grid-scroll-shadow-width)) var(--grid-scroll-shadow-color) inset,
		0 0 var(--grid-scroll-shadow-width) calc(0px - var(--grid-scroll-shadow-width)) var(--grid-scroll-shadow-color) inset,
		0 calc(0px - var(--grid-scroll-shadow-width)) var(--grid-scroll-shadow-width) calc(0px - var(--grid-scroll-shadow-width)) var(--grid-scroll-shadow-color) inset,
		0 0 var(--grid-scroll-shadow-width) calc(0px - var(--grid-scroll-shadow-width)) var(--grid-scroll-shadow-color) inset;
}

.grid-scrollable-view-l {
	box-shadow: 0 0 var(--grid-scroll-shadow-width) calc(0px - var(--grid-scroll-shadow-width)) var(--grid-scroll-shadow-color) inset,
		0 0 var(--grid-scroll-shadow-width) calc(0px - var(--grid-scroll-shadow-width)) var(--grid-scroll-shadow-color) inset,
		0 0 var(--grid-scroll-shadow-width) calc(0px - var(--grid-scroll-shadow-width)) var(--grid-scroll-shadow-color) inset,
		var(--grid-scroll-shadow-width) 0 var(--grid-scroll-shadow-width) calc(0px - var(--grid-scroll-shadow-width)) var(--grid-scroll-shadow-color) inset;
}

.grid-scrollable-view-t.grid-scrollable-view-b {
	box-shadow: 0 var(--grid-scroll-shadow-width) var(--grid-scroll-shadow-width) calc(0px - var(--grid-scroll-shadow-width)) var(--grid-scroll-shadow-color) inset,
		0 0 var(--grid-scroll-shadow-width) calc(0px - var(--grid-scroll-shadow-width)) var(--grid-scroll-shadow-color) inset,
		0 calc(0px - var(--grid-scroll-shadow-width)) var(--grid-scroll-shadow-width) calc(0px - var(--grid-scroll-shadow-width)) var(--grid-scroll-shadow-color) inset,
		0 0 var(--grid-scroll-shadow-width) calc(0px - var(--grid-scroll-shadow-width)) var(--grid-scroll-shadow-color) inset;
}

.grid-scrollable-view-l.grid-scrollable-view-r {
	box-shadow: 0 0 var(--grid-scroll-shadow-width) calc(0px - var(--grid-scroll-shadow-width)) var(--grid-scroll-shadow-color) inset,
		calc(0px - var(--grid-scroll-shadow-width)) 0 var(--grid-scroll-shadow-width) calc(0px - var(--grid-scroll-shadow-width)) var(--grid-scroll-shadow-color) inset,
		0 0 var(--grid-scroll-shadow-width) calc(0px - var(--grid-scroll-shadow-width)) var(--grid-scroll-shadow-color) inset,
		var(--grid-scroll-shadow-width) 0 var(--grid-scroll-shadow-width) calc(0px - var(--grid-scroll-shadow-width)) var(--grid-scroll-shadow-color) inset;
}

.grid-selection-col-marker {
	position: absolute;
	box-sizing: border-box;
	border-radius: var(--grid-selection-border-radius);
	transition: all var(--grid-transition-duration);
	pointer-events: none;
	z-index: 3;
	@apply border-b-2 border-b-primary;
}

.grid-selection-row-marker {
	position: absolute;
	box-sizing: border-box;
	border-radius: var(--grid-selection-border-radius);
	transition: all var(--grid-transition-duration);
	pointer-events: none;
	z-index: 3;
	@apply border-r-2 border-r-primary;
}

.grid-selection-col-marker-disabled {
	border-bottom-color: var(--grid-selection-disabled-border-color);
}
.grid-selection-row-marker-disabled {
	border-right-color: var(--grid-selection-disabled-border-color);
}

/*********************/
/*Expand formula*/
/*********************/

.grid-expand-rows-indicator {
	background: white;
	box-shadow: 0 0 0 1px white;
	width: var(--grid-expand-rows-indicator-width);
	height: var(--grid-expand-rows-indicator-width);
	box-sizing: border-box;
	position: absolute;
	transition: all var(--grid-transition-duration);
	cursor: crosshair;
	z-index: 2;
	@apply border border-primary;
}

.grid-expand-rows-indicator-disabled {
	border: solid 1px var(--grid-selection-disabled-border-color);
}

.grid-expand-rows-rect {
	position: absolute;
	box-sizing: border-box;
	transition: all var(--grid-transition-duration);
	pointer-events: none;
	@apply bg-primaryLight;
}
